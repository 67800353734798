@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
/* span{
    color:#344747 !important;
} */
/* h1,h2,h3,h4,h5,h6,p,label{
    color:#344747 !important;
}
svg{
    color:#344747 !important;
}
svg{
    color:#344747 !important;
}
th, td, tr{
    color: inherit !important;
}
::placeholder{
    color:#344747 !important;
} */
/* svg{
    color:inherit !important;
} */

table.table-small {
    border: 1px solid rgb(204, 204, 204);
}

table.table-small tr td {
    padding-left: 5px;
}

table.table-small tr {
    border: 1px solid rgb(204, 204, 204);
}

.MuiBreadcrumbs-ol li:last-child a span {
    font-weight: bold;
    color: #000;
    opacity: 1;
}

.hideForonbording {
    margin-left: 0px !important;
}

.MuiButton-containedPrimary {
    color: #fff !important;
}

.subscription_card {
    position: relative;
    height: 50vh;
    overflow: hidden;
    margin: 16px;
    padding-top: 96px;
    padding-bottom: 160px;
    opacity: 1;
    background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
    color: rgb(52, 71, 103);
    border-radius: 1rem;
}

.subscription_card img {
    width: 105rem;
    position: absolute;
    top: 0px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
}
.MuiBox-root.css-x1q3ng {
    height: calc(80vh - 10px);
    min-height: calc(80vh - 10px);
}

.css-1e6b0rg {
    height: calc(80vh - 10px);
    min-height: calc(80vh - 10px);
}

.css-1snzrqz-MuiButtonBase-root-MuiButton-root {
    background: linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%) border-box !important;
    border: 0 !important;
}

.css-buwai7-MuiButtonBase-root-MuiButton-root {
    background: linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%) border-box !important;
    border: 0 !important;
}

.fc-col-header {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

.fc-col-header th div a {
    color: #fff !important;
    font-weight: bold;
    line-height: 35px;
}

.fc .fc-daygrid-bg-harness {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd);
    font-weight: bold;
}
/* Full Calendar */
a,
button {
  cursor: pointer !important;
  overflow: visible;
  overflow: initial;
}

.fc-toolbar-title {
  color: dark !important;
}

a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}

a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}

a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}

div.fc-daygrid-event-harness {
  top: unset;
}

.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
  top: 0px;
  bottom: unset;
}

.fc .fc-scrollgrid-section-header>*,
.fc .fc-scrollgrid-section-footer>* {
  border-right: 0px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #282828 !important;
}

.fc-col-header-cell.fc-day.fc-day-mon {
  /* color: white; */
  color: #282828;
  border-right: 0px;
  border-left: 0px;
}

.fc-col-header-cell.fc-day.fc-day-tue {
  /* color: white; */
  color: #282828;
  border-left: 0px;
  border-right: 0px;
}

.fc-col-header-cell.fc-day.fc-day-wed {
  /* color: white; */
  color: #282828;
  border-right: 0px;
  border-left: 0px;
}

.fc-col-header-cell.fc-day.fc-day-thu {
  /* color: white; */
  color: #282828;
  border-left: 0px;
  border-right: 0px;
}

.fc-col-header-cell.fc-day.fc-day-fri {
  /* color: white; */
  color: #282828;
  border-right: 0px;
  border-left: 0px;
}

.fc-col-header-cell.fc-day.fc-day-sat {
  /* color: white; */
  color: #282828;
  border-left: 0px;
  border-right: 0px;
}

.fc-col-header-cell.fc-day.fc-day-sun {
  /* color: white; */
  color: #282828;
  border-left: 0px;
  border-right: 0px;
}

.fc-daygrid-event {
  border-radius: 10px !important;
  text-align: center !important;
}

.fc-day {
  /* color: white; */
  color: #282828;
}

.fc-day.fc-day-mon {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-day.fc-day-tue {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-day.fc-day-wed {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-day.fc-day-thu {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-day.fc-day-fri {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-day.fc-day-sat {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-day.fc-day-sun {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid>td {
  border: 0px;
}

.fc .fc-scrollgrid-section>td {
  border: 0;
}

/* React Kanban */
body .react-kanban-column {
  background: linear-gradient(127.09deg,
      rgba(6, 11, 40, 0.94) 19.41%,
      rgba(10, 14, 35, 0.49) 76.65%);
  background-color: unset !important;
  border-radius: 15px;
}

.react-kanban-column {
  width: 500px;
}

.react-kanban-card-adder-form {
  width: 100%;
  background-color: red;
  border-radius: 15px;
}

.react-kanban-card-adder-button {
  display: none;
  background-color: red !important;
  border-radius: 15px;
}

/* Text Editor */

body .ql-toolbar.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-container.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-snow .ql-picker-label {
  color: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.92) !important;
}

/* .ql-snow .ql-picker-label:hover {
    color: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-stroke:hover {
    stroke: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-fill:hover {
    fill: rgba(255, 255, 255, 0.92) !important;
  } */

.ql-picker-options {
  background: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

.ql-container.ql-snow {
  color: rgb(160, 174, 192);
  min-height: 88px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.example {
  /* margin: 20px 0; */
  /* margin-bottom: 20px; */
  margin-top: 10%;
  padding: 30px 50px;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%)
}

.ant-notification {
  top: 90px !important;
}
div#Hazardous-Materials {
  width: 100% !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.css-1xslqe-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: #4a5568;
  border-radius: 11px !important;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-s2ec2r-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  opacity: 1;
  transform: none;
  min-width: 750px;
  max-height: 224px;
  width: 400px;
  transition: opacity 289ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 192ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 209px;
  left: 478px;
  transform-origin: 375px 0px;
  margin-top: 10px;
} */

.css-ujqrc-MuiButtonBase-root-MuiRadio-root .MuiSvgIcon-root{
    fill: #0075ff !important;
}
.css-ujqrc-MuiButtonBase-root-MuiRadio-root .MuiSvgIcon-root{
    fill: #0075ff !important;
}
.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffff;
    z-index: 2;
    cursor: pointer;
  }

  .loading-div{
    width: 200px;
    height: 100px;
    margin: 22% auto;
    margin-left: 50%;
    position: relative;
  }

  .loading span{
    width : 20px;
    height : 20px;
    margin: 0 5px;
    background-color: #0a0e2d;
    border-radius: 50%;
    display: inline-block;
    z-index: 10;
    -webkit-animation-name: dots;
            animation-name: dots;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  .loading span:nth-child(2){
    background-color: #cccccc;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .loading span:nth-child(3){
    background-color: #000000;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  @-webkit-keyframes dots{
    50%{
      opacity: 0;
      -webkit-transform: scale(0.7) translateY(10px);
              transform: scale(0.7) translateY(10px)
    }
  }

  @keyframes dots{
    50%{
      opacity: 0;
      -webkit-transform: scale(0.7) translateY(10px);
              transform: scale(0.7) translateY(10px)
    }
  }
.css-ujqrc-MuiButtonBase-root-MuiRadio-root .MuiSvgIcon-root{
    fill: #0075ff !important;
}
.css-ujqrc-MuiButtonBase-root-MuiRadio-root .MuiSvgIcon-root{
    fill: #0075ff !important;
}
.ag-theme-balham .ag-cell {
  border: 1px solid #ddd;
  border-bottom: 0;
}

.ag-theme-balham .ag-header {
  background-color: #2356a5;
  height: 60px !important;
  min-height: 60px !important;
}

.ag-header-cell-label {
  color: #fff;
}

.ag-theme-balham [class^="ag-"] {
  font-size: 16px;
}

.ag-header-row.ag-header-row-column {
  height: 58px !important;
}
.ag-unselectable {
  -webkit-user-select: text !important; /* Safari */
  -ms-user-select: text !important; /* IE 10 and IE 11 */
  user-select: text !important; /* Standard syntax */
}

/* .tracking-reset,
.css-dvatjy-MuiButtonBase-root-MuiButton-root {
  background-color: #e31a1a !important;
} */

